import 'src/board.css.ts.vanilla.css?source=#H4sIAAAAAAAAA8VWf2+bMBD9v5/C0jQplWrEj0AS+mEqGx/ELWBkmyTdtO8+G7aEzYaoVNoUKZIv9x73zu8uBFQQyV5eotNJvcZZhL4/IMS46mrynqOyhsuzCZCaVy3mGhqVowJaDdKGX3ulefmOC2Eirb799OMh+JM3HngxriRnWGmie2VQtZA5+hLtzYdavjNn+pij7SHthsf2CiRWUENhuFvRwvO0OMtlAwOnKc1ENWApzqZGCR0QvTk8oYa3DblswicUlfLx0QWYMvqmXca4eoJKCLYkKiPJlniRlCwBWWFyMg8w+UgL0yT8bAsTcwmr+ngP6Cpb3cxkbTO3A6g0vsWKf4McRdnYL6I60yosiebCRG2oE4qbU2sdYpTyE3gI04HwlkqoMv3QMLmTKAy/2uMReHXUt/Onpw0hKiQDIzhE5qmc3Qzh1hlQPZQ6QrAWHf5dn+2AD1BPATWU+i6CThFUaC2auxg5xUjborsQjfWRF29/6clRbAw4NuJE5MZjDZ8LrVCXz8pdT0hdwrEb6ymlSylHP61lrPBRnEACMwjRdyMzKd7sqWXXWYIUdjBjqSvFHJiWjJa+ucnWz41j+iwkaelbE9m86bdeb2VLdpyFLLh+FjM7W37E7r+umcm+TOJxX15XdBTt452v4oBBSfp6vIBrOiRk77uqvf8FZKEorwyH97C+cR1hjLfVMGBTycluG6XRR19JkhWvJD6Mo5D8E4VrLwQNSHyWxOxn++1TEIwGa5w/Z/8w/Mq/KCc/Xsx3Afsh/yc5VuUhDAsAAA==';
export var cell = 'board__1vvsj264';
export var grid = 'board__1vvsj263';
export var gridNoHeaders = 'board__1vvsj262';
export var header = 'board__1vvsj261';
export var innerCell = 'board__1vvsj265';
export var innerCellNote = 'board__1vvsj269';
export var innerCellSelected = 'board__1vvsj266';
export var innerCellStrongNote = 'board__1vvsj26a';
export var innerCellValue = 'board__1vvsj267';
export var noteItem = 'board__1vvsj268';
export var wrapper = 'board__1vvsj260';